import React from 'react';

import SpinnerSmall from 'components/Layout/Spinner/SpinnerSmall';
import SpinnerMedium from 'components/Layout/Spinner/SpinnerMedium';
import SpinnerLarge from 'components/Layout/Spinner/SpinnerLarge';
import SpinnerSmallWhite from '../Spinner/SpinnerSmallWhite';

import styles from './Loading.module.scss';

interface IProps {
  size: 'small' | 'medium' | 'large' | 'small-white';
}


const Loading = (props: IProps) => {
  const { size } = props;
  return (
    <div className={(size === 'small' && styles.loading_small) || (size === 'medium' && styles.loading_medium) || (size === 'large' && styles.loading_large) || ''}>
      {size === 'small' && <SpinnerSmall />}
      {size === 'medium' && <SpinnerMedium />}
      {size === 'large' && <SpinnerLarge />}
      {size === 'small-white' && <SpinnerSmallWhite />}
    </div>
  );
};

export default Loading;
