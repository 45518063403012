import React from 'react';
import styles from './SpinnerMedium.module.scss'; 

const SpinnerMedium = () => {
	return (
		<div className={styles['lds-spinner-medium']}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
	);
}

export default SpinnerMedium;
