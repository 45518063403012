import React, { useState } from 'react';

import styles from './Notification.module.scss';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  msg: string;
  buttonMsg?: string;
  autoHideDuration: number | null;
  buttonFunction?: () => any;
  type: string;
}

const Notification = (props: IProps) => {
  const { msg, autoHideDuration, buttonMsg, buttonFunction, type } = props;
  let typeColor: string;
  let Icon: JSX.Element;

  
  switch (type) {
    case 'warning': {
      typeColor = '#ffa000';
      Icon = <WarningIcon />;
      break;
    }
    case 'error': {
      typeColor = '#d32f2f';
      Icon = <ErrorIcon />;
      break;
    }
    case 'info': {
      typeColor = '#1976d2';
      Icon = <InfoIcon />;
      break;
    }
    case 'save': {
      typeColor = '#43a047';
      Icon = <CheckCircleIcon />;
      break;
    }
    default: {
      typeColor = '#AA4399';
      Icon = <InfoIcon />;
      break;
    }
  }

  const useStyles = makeStyles(() => ({
    snackBarWrapper: {
      'border': `2px solid ${typeColor}`,
      'borderRadius': '6px',
      '& > div': {
        flexWrap: 'inherit',
        padding: '3px 16px',
        color: typeColor,
        background: 'white',
      },
      '& .MuiSnackbarContent-action': {
        paddingLeft: '.625rem',
        marginRight: '-0.875rem',
      },
    },
    snackBarContent_icon: {
      '& svg': {
      verticalAlign: 'middle',
      },
    },
    snackBarFunctionButton: {
      fontWeight: 400,
      border: `1px solid ${typeColor}`,
    },
    snackBarCloseButton: {
      marginLeft: '0.2rem',
    },
  }));

  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      className={classes.snackBarWrapper}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      message={
        <div className={styles.notification_msgWrapper}>
          <div className={classes.snackBarContent_icon}>{Icon}</div>
          <div>{msg}</div>
        </div>
      }
      action={[
        (
          buttonFunction &&
          <Button
            className={classes.snackBarFunctionButton}
            key="button"
            color="secondary"
            onClick={buttonFunction}
          >
            {buttonMsg}
          </Button>
        ),
        <IconButton
          className={classes.snackBarCloseButton}
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    />
  );
};

export default Notification;
