import React, { useState } from 'react';
import {ReactComponent as PayTipJarLogo} from 'assets/pay-tip-logo-with-leafs.svg';
import { BackendApp } from 'libs/App';
import Notification from 'components/Layout/Notification/Notification';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {ReactComponent as FacebookIcon} from 'assets/facebook_icon.svg';

import styles from './SignIn.module.scss';
// import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { checkEmail, checkEmpty } from 'utils/validators';
import Loading from 'components/Layout/Loading/Loading';

// Initialize backend methods
const backendLib = BackendApp();

const useStyles = makeStyles(() => ({
  button: {
    fontWeight: 700,
  },
  facebookButton: {
    margin: '1em 0rem 1.250rem 0rem',
  },
}));

interface IProps {
  // history: {
  //   goBack: () => void,
  // },
}

const SignIn = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState({login: '', password: ''});
  const [isTouched, setIsTouched] = useState({login: false, password: false});
  const [notificationMsg, setNotificationMsg] = useState('');
  const [loginProcessing, setLoginProcessing] = useState(false);

  const classes = useStyles();

  // const handleBack = () => {
  //   history.goBack();
  // };

  const handleLogin = (event: React.FormEvent<EventTarget>) => {
    setLoginProcessing(true);
    setNotificationMsg('');
    event.preventDefault();
    backendLib.userSignIn(user.login, user.password)
    .catch((error: any) => {
      console.log('Login error:', error);
      setLoginProcessing(false);
      if (error.code !== -100) {
        setNotificationMsg(t('wrong_login_or_password'));
      }
    });
  };

  const handleFacebookButton = () => {
    backendLib.userSignInWithFacebook();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsTouched({...isTouched, [event.target.name]: true});
    setUser({...user, [event.target.name]: event.target.value});
  };

  return (
    <div className={styles.signIn}>
      <div className={styles.signIn_leftSection}>
        <PayTipJarLogo className={styles.signIn_jarIcon} />
      </div>
      <div className={styles.signIn_centerSection}>
        {/* <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton> */}
      </div>
      <form
        onSubmit={handleLogin}
        className={styles.signIn_rightSection}
      >
        <div className="header1">
          {t('login')}
        </div>
        <div className="header3 mt-5 mb-30">
          {t('login_with_social')}
        </div>
        <Button
          className={classes.button}
          size="large"
          color="primary"
          variant="contained"
          type="button"
          startIcon={<FacebookIcon className={styles.signIn_facebookIcon} />}
          onClick={handleFacebookButton}
        >
          {t('login_with_facebook')}
        </Button>
        <div className="justify-center header3 mt-15">
          {t('or_login_with_email')}
        </div>
        <TextField
          fullWidth
          autoFocus
          autoComplete="off"
          name="login"
          label="E-mail"
          helperText={isTouched.login ? checkEmail(user.login) : null}
          margin="normal"
          variant="outlined"
          type="email"
          onChange={handleChange}
          error={isTouched.login && !!checkEmail(user.login)}
        />
        <TextField
          fullWidth
          autoComplete="off"
          name="password"
          label="Hasło"
          helperText={isTouched.password ? checkEmpty(user.password, t('password_is_required')) : null}
          margin="normal"
          variant="outlined"
          type="password"
          onChange={handleChange}
          error={isTouched.password && !!checkEmpty(user.password)}
        />
        <Button
          className={`${classes.button} ${classes.facebookButton}`}
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          type="submit"
          disabled={!!checkEmpty(user.password) || !!checkEmail(user.login)}
        >
          {loginProcessing ? <Loading size="small-white" /> : 'Zaloguj'}
        </Button>
        <div className={styles.signIn_rightSection__bottomLinks}>
        <div className="header3">{t('dont_have_account')}</div>
          <a
            href="https://paytip.pl/sign-up"
            className="base-link ml-5"
          >
            {t('sign_in')}
          </a>
        </div>
      </form>
      {notificationMsg && (
        <Notification
          type="error"
          msg={notificationMsg}
          autoHideDuration={6000}
        />
      )}
    </div>
  );
};

export default SignIn;
