import React from 'react';
import styles from './SpinnerSmallWhite.module.scss'; 

const SpinnerSmallWhite = () => {
	return (
		<div className={styles['lds-spinner-small-white']}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
	);
}


export default SpinnerSmallWhite;
