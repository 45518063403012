import React, { useState, useEffect } from 'react';
import { checkEmpty } from 'utils/validators';
import { BackendApp } from 'libs/App';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pl';
import Loading from 'components/Layout/Loading/Loading';
import styles from './ReportDialog.module.scss';

// Initialize backend methods
const backendLib = BackendApp();

moment.locale('pl');

const useStyles = makeStyles(() => ({
  titleWrapper: {
    display: 'grid',
    gridAutoFlow: 'column',
  },
  closeButton: {
    padding: '18px',
    marginLeft: '2rem',
    justifySelf: 'right',
  },
  inputsMargin: {
    margin: '1rem',
  },
  inputFullWidth: {
    display: 'grid',
  },
}));

interface PropsInterface {
  openDialog: boolean;
  toggleClick: () => void;
  reportParameters: {
    type: string,
    parameters: any,
  } | undefined;
}

const ReportDialog = (props: PropsInterface) => {
  const { reportParameters, openDialog, toggleClick } = props;
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<any>(moment().subtract(1, 'days').startOf('day'));
  const [endDate, setEndDate] = useState<any>(moment().endOf('day'));
  const [inputParameter, setInputParameter] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [dateError, setDateError] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [base64File, setBase64File] = useState('');

  const classes = useStyles();

  const { t } = useTranslation();

  const dateUtcOffset = moment().utcOffset();

  const generateReport = () => {
    setLoading(true);
    setBase64File('');
    let payload = {};
    if (reportParameters!.parameters.d) {
      payload = {
        df: moment(startDate).add(dateUtcOffset, 'm').valueOf(),
        dt: moment(endDate).add(dateUtcOffset, 'm').valueOf(),
        f: inputParameter,
        e: 'BASE64_XLS',
      };
    } else {
      payload = {
        f: inputParameter,
        e: 'BASE64_XLS',
      };
    }
    backendLib._callAPI('boReportAPI', {
      n: reportParameters!.type,
      d: payload,
    })
    .then((result: any) => {
      setBase64File(`data:${result.data.d.m};base64,${result.data.d.b}`);
      setLoading(false);
    })
    .catch((error: any) => {
      console.error(error.message);
      setError(error.message);
      setLoading(false);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsTouched(true);
    setInputParameter(event.target.value);
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  useEffect(() => {
    setBase64File('');
    setStartDate(moment().subtract(1, 'days').startOf('day'));
    setEndDate(moment().endOf('day'));
    setError('');
    if (reportParameters && !reportParameters?.parameters && open) {
      setLoading(true);
      backendLib._callAPI('boReportAPI', {
        n: reportParameters.type,
        d: {
          e: 'BASE64_XLS',
        },
      })
      .then((result: any) => {
        setBase64File(`data:${result.data.d.m};base64,${result.data.d.b}`);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error(error.message);
        setError(error.message);
        setLoading(false);
      });
    }
  }, [open, reportParameters]);

  useEffect(() => {
    if (endDate <= startDate) {
      setDateError(t('date_from_to_error'));
    } else {
      setDateError('');
    }
  }, [endDate, startDate, t]);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="pl">
      <Dialog
        onClose={toggleClick}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle
          disableTypography
          className={classes.titleWrapper}
        >
          <h3>
            {
              reportParameters?.parameters
              ?
              (
                <>
                  {t('report_parameters')}<span className={styles.reportDialog_reportTitle}> - {reportParameters.type}</span>
                </>
              )
              :
              (
                <>
                  {t('generating_report')}<span className={styles.reportDialog_reportTitle}> - {reportParameters ? reportParameters.type : ''}</span>
                </>
              )
            }
          </h3>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={toggleClick}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          {
            loading ?
              <Loading size="medium" />
            :
            (
              <>
                {
                  reportParameters?.parameters && !base64File && !error &&
                  (
                    <div>
                      {
                        reportParameters.parameters.d && (
                          <div>
                            <DateTimePicker
                              label={`${reportParameters.parameters.d.l} - ${t('from')}`}
                              inputVariant="outlined"
                              value={startDate}
                              onChange={setStartDate}
                              className={classes.inputsMargin}
                              format="D/MM/YY HH:mm:ss"
                              required={reportParameters.parameters.d.r}
                            />
                            <DateTimePicker
                              label={`${reportParameters.parameters.d.l} - ${t('to')}`}
                              inputVariant="outlined"
                              value={endDate}
                              onChange={setEndDate}
                              className={classes.inputsMargin}
                              format="D/MM/YY HH:mm:ss"
                              error={!!dateError}
                              helperText={dateError}
                              required={reportParameters.parameters.d.r}
                            />
                          </div>
                        )
                      }
                      {
                        reportParameters.parameters.f?.t === 'NUMBER' && (
                          <div>
                            <TextField
                              label={reportParameters.parameters.f.l}
                              variant="outlined"
                              type="number"
                              className={`${classes.inputsMargin} ${classes.inputFullWidth}`}
                              onChange={handleChange}
                              required={reportParameters.parameters.f.r}
                              error={isTouched && !!checkEmpty(inputParameter) && reportParameters.parameters.f.r}
                              helperText={isTouched && reportParameters.parameters.f.r ? checkEmpty(inputParameter) : null}
                            />
                          </div>
                        )
                      }
                      {
                        reportParameters.parameters.f?.t === 'TEXT' && (
                          <div>
                            <TextField
                              label={reportParameters.parameters.f.l}
                              variant="outlined"
                              className={`${classes.inputsMargin} ${classes.inputFullWidth}`}
                              onChange={handleChange}
                              required={reportParameters.parameters.f.r}
                              error={isTouched && !!checkEmpty(inputParameter) && reportParameters.parameters.f.r}
                              helperText={isTouched && reportParameters.parameters.f.r ? checkEmpty(inputParameter) : null}
                            />
                          </div>
                        )
                      }
                    </div>
                  )
                }
                {
                  base64File
                  ?
                  (
                    <div className={styles.reportDialog_infoText}>
                      <p>{t('report_ready_to_download')}</p>
                      <p>{t('click_button_to_download')}</p>
                    </div>
                  )
                  :
                  (
                    <>
                      {
                        error &&  <p className={styles.reportDialog_infoText}>{error}</p>
                      }
                    </>
                  )
                }
              </>
            )
          }
        </MuiDialogContent>
        <MuiDialogActions>
          {
            reportParameters?.parameters
            ?
            (
              <>
                {
                  base64File
                  ?
                  (
                    <Button
                      href={base64File}
                      color="primary"
                      download={`${reportParameters ? reportParameters.type : ''}-${moment().format('D/MM/YY-hh:mm:ss')}.xlsx`}
                      disabled={!base64File}
                    >
                      {t('download_report')}
                    </Button>
                  )
                  :
                  (
                    <Button
                      onClick={generateReport}
                      color="primary"
                      disabled={loading || !!error || !!dateError || (reportParameters.parameters.f?.r && !!checkEmpty(inputParameter))}
                    >
                      {t('generate_report')}
                    </Button>
                  )
                }
              </>
            )
            :
            (
              <Button
                href={base64File}
                color="primary"
                download={`${reportParameters ? reportParameters.type : ''}-${moment().format('D/MM/YY-hh:mm:ss')}.xlsx`}
                disabled={!base64File}
              >
                {t('download_report')}
              </Button>
            )
          }
        </MuiDialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default ReportDialog;
