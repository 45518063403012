import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from 'userSlice';

export const store = configureStore({
  reducer: {
    currentUser: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
