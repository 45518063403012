import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BackendApp } from 'libs/App';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DataTable from 'components/DataTable/DataTable';
import styles from './Reports.module.scss';
import Loading from 'components/Layout/Loading/Loading';
import ReportDialog from './ReportDialog/ReportDialog';

// Initialize backend methods
const backendLib = BackendApp();

interface Data {
  name: string | JSX.Element;
  action: string | JSX.Element;
}

interface HeadCell {
  name: string;
  label: string;
  options?: object;
}

const createData = (
  name: string | JSX.Element,
  action: string | JSX.Element,
): Data => {
  return { name, action };
};

const Reports = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  let reportsParentTitle = props.location.pathname.replace('/', '').replace('-', ' ');
  reportsParentTitle = reportsParentTitle[0].toUpperCase() + reportsParentTitle.slice(1);

  const [rows, setRows] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [reportParameters, setReportParameters] = useState<{type: string, parameters: any}>();
  const [error, setError] = useState('');

  const columns: HeadCell[] =
  [
    {
     name: 'name',
     label: t('report_name'),
     options: {
      filter: true,
      sort: true,
      setCellProps: () => (
        {
          colSpan: loading || error ? 99 : 0,
          style: loading || error ? {textAlign: 'center'} : null,
        }
      ),
     },
    },
    {
     name: 'action',
     label: t('action'),
     options: {
      filter: false,
      sort: false,
      download: false,
      setCellHeaderProps: () => (
        {
          style: {
            width: '20%',
          },
        }
      ),
      setCellProps: () => (
        {
          style: {
            display: loading || error ? 'none' : 'table-cell',
          },
        }
      ),
     },
    },
  ];

  const toggleClick = useCallback(() => {
    setOpenDialog((state) => !state);
  }, []);

  const setParameters = (parameters: {type: string, parameters: any}) => {
      setReportParameters(parameters);
  };

  useEffect(() => {
    setLoading(true);
    setRows(
      [
        {
          name: <Loading size="medium" />,
          action: '',
        },
      ],
    );
    backendLib._callAPI('boAPI', {n: 'ReportsGet'}).then((availableReports: any) => {
      const subReports: Data[] = [];
      setLoading(false);
      availableReports.data.d.forEach((report: any) => {
        if (report.v.c.includes(reportsParentTitle)) {
          subReports.push(
            createData(
              report.v.t,
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveAltOutlinedIcon />}
                onClick={
                  () => {
                    toggleClick();
                    setParameters(
                      {
                        type: report.k,
                        parameters: report.v.p,
                      },
                    );
                  }
                }
              >
                {t('generate')}
              </Button>,
              ),
          );
        }
      });
      setRows(subReports);
    })
    .catch((error: any) => {
      setLoading(false);
      setError(error.message);
      console.error(error.message);
    });
  }, [reportsParentTitle, t, toggleClick]);

  return (
    <div className={styles.reports}>
      <DataTable
        columns={columns}
        rows={error ? [{name: error, action: ''}] : rows}
        tableTitle={reportsParentTitle}
      />
      <ReportDialog
        openDialog={openDialog}
        toggleClick={toggleClick}
        reportParameters={reportParameters}
      />
    </div>
  );
};

export default withRouter(Reports);
