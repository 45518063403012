import React, { useState } from 'react';

import styles from './Notification.module.scss';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import withStyles from '@material-ui/styles/withStyles';
import { innerHTMLTranslation } from 'utils/utils';

// TODO: Add conditional types
interface Props {
  msg?: string;
  htmlMsg?: string;
  buttonMsg?: string;
  autoHideDuration: number | null;
  buttonFunction?: () => any;
  type: 'update' | 'error' | 'info' | 'save';  
  show?: boolean;
  onClose?: any;
}

const NotificationReopenable = (props: Props) => {
  const { msg, htmlMsg, autoHideDuration, buttonMsg, buttonFunction, type, show, onClose } = props;
  let typeColor: string;
  let Icon: JSX.Element;

  switch (type) {
    case 'update': {
      typeColor = '#AA4399';
      Icon = <InfoIcon />;
      break;
    }
    case 'error': {
      typeColor = '#E9649B';
      Icon = <ErrorIcon />;
      break;
    }
    case 'info': {
      typeColor = '#AA4399';
      Icon = <InfoIcon />;
      break;
    }
    case 'save': {
      typeColor = '#2B0096';
      Icon = <CheckCircleIcon />;
      break;
    }
    default: {
      typeColor = '#AA4399';
      Icon = <InfoIcon />;
      break;
    }
  }

  const CssSnackbar = withStyles({
    root: {
      'border': `2px solid ${typeColor}`,
      'borderRadius': '.6rem',
      '& .MuiSnackbarContent-root': {
        fontSize: '1.4rem',
        display: 'grid',
        gridAutoFlow: 'column',
        padding: '3px 16px',
        alignItems: 'start',
        color: typeColor,
        background: 'white',
      },
      '& .MuiSnackbarContent-action': {
        paddingLeft: '1rem',
        marginRight: '-1.4rem',
        display: 'grid',
        height: '100%',
        gridAutoFlow: 'column',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '2.2rem',
        verticalAlign: 'middle',
      },
    },
  })(Snackbar);

  const CssButton = withStyles({
    root: {
      fontSize: '1.4rem',
      fontWeight: 400,
      border: `1px solid ${typeColor}`,
    },
  })(Button);

  const CssIconButton = withStyles({
    root: {
      margin: '-0.2rem 0 0 0.2rem',
      alignSelf: 'start',
    },
  })(IconButton);
  

  const handleClose = () => {    
    if (onClose) {
      onClose();
    }
  };

  return (
    <CssSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={show}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      message={
        <div className={styles.notification_msgWrapper}>
          <div className={styles.notification_icon}>{Icon}</div>
          {htmlMsg ? <div dangerouslySetInnerHTML={innerHTMLTranslation(htmlMsg)} /> : <div className={styles.notification_text}>{msg}</div>}
        </div>
      }
      action={[
        (
          buttonFunction &&
          <CssButton key="button" color="secondary" onClick={buttonFunction}>
            {buttonMsg}
          </CssButton>
        ),
        <CssIconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </CssIconButton>,
      ]}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    />
  );
};

export default NotificationReopenable;
