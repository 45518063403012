import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from 'userSlice';
import User, { emptyUser } from 'interfaces/user';
import { BackendApp } from 'libs/App';
import styles from './App.module.scss';
import { initFirebase } from 'config/firebase';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Routes } from 'config/Routes';
import { AvailableUserRoles } from 'config/userRoles';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import ApplicationBar from 'components/ApplicationBar/ApplicationBar';
import Loading from 'components/Layout/Loading/Loading';
import Notification from 'components/Layout/Notification/Notification';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isUserAllowed } from 'utils/isUserAllowed';
import { useTranslation } from 'react-i18next';
import MenuDrawer from 'components/MenuDrawer/MenuDrawer';
import RoutesInterface from 'interfaces/routes';
import ScoreOutlinedIcon from '@material-ui/icons/ScoreOutlined';
import Reports from 'views/Reports/Reports';
import MoneyIcon from '@material-ui/icons/Money';
import SignIn from 'views/SignIn/SignIn';

// Initialize backend methods
const backendLib = BackendApp();

// Initialize firebase
initFirebase();

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  }),
);

const App = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState<User>(emptyUser);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(true);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [reportRoutsIsBuild, setReportRoutsIsBuild] = useState(false);

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // TODO: Think about promiseeAll
    firebase.auth().onAuthStateChanged((user: object | any) => {
      setLoader(true);
      // Try to login user
      if (user) {
        // If user login and password is correct get his claims
        if (user.emailVerified) {
          user.getIdTokenResult()
          .then((idTokenResult: any) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims) {
              // Check if user have permission to see dashboard
              setNotificationMsg('');
              if (idTokenResult.claims.A1) {
                // Check user roles
                const userRoles: string[] = [];
                let userReports: string[] = [];
                AvailableUserRoles.forEach((role, index) => {
                  // Build roles array
                  if (role in idTokenResult.claims && idTokenResult.claims[role]) {
                    userRoles.push(role);
                    // If role A4 - reports
                    if (role === 'A4') {
                      // Get alliable reports and add it to currentUser state
                      backendLib._callAPI('boAPI', {n: 'ReportsGet'}).then((availableReports: any) => {
                        availableReports.data.d.forEach((report: any) => {
                          userReports.push(report.v.c);
                        });

                        // Remove duplicates
                        userReports = userReports.filter((item, pos) => userReports.indexOf(item) === pos);

                        setCurrentUser((state) => ({
                          ...state,
                          userId: user.uid,
                          email: user.email,
                          userRoles,
                          userReports,
                        }));
                        setLoader(false);
                      });
                    }
                  } else if (AvailableUserRoles.length - 1 === index) {
                    setCurrentUser((state) => ({
                      ...state,
                      userId: user.uid,
                      email: user.email,
                      userRoles,
                    }));
                    setLoader(false);
                  }
                });
              } else {
                // If no - logout the user and clear store data
                console.log('You are not an admin.');
                setNotificationMsg(t('no_permissions'));
                backendLib.userSignOut();
                setCurrentUser(emptyUser);
                setLoader(false);
              }
            }
          });
        } else {
          console.log('Please verify your email');
          setNotificationMsg(t('verify_email'));
          backendLib.userSignOut();
          setCurrentUser(emptyUser);
          setLoader(false);
        }
      } else {
        console.log('Not logged in.');
        setCurrentUser(emptyUser);
        setLoader(false);
      }
    });
  }, [t]);

  // Update store when logged in
  useEffect(() => {
    if (currentUser.userId) {
      dispatch(setUser(currentUser));
    }
  }, [currentUser, currentUser.userId, dispatch]);

  const RoutesBuilder = () => {    
    // See Routes.ts

    // Push reports URL from BE to Routes array
    if (!reportRoutsIsBuild && currentUser.userReports.length) {
      const children: RoutesInterface[] = [];

      // By default Reports sub menu appears in the bottom of menu
      const startingMenuPos = Math.max.apply(Math, Routes.map((o) => o.menuOrder));

      currentUser.userReports.forEach((report: any, index: number) => {
        children.push(
          {
            parentMenu: false,
            path: report.toLowerCase().replace(' ', '-'),
            menuOrder: startingMenuPos + index + 1,
            exact: true,
            icon: (iconStyle?: string) => <MoneyIcon className={iconStyle} />,
            component: Reports,
            title: report.replace('/', ''),
            role: ['A4'],
          },
        );
      });

      (Routes as RoutesInterface[]).push(
          {
            parentMenu: true,
            path: '',
            menuOrder: 3,
            exact: false,
            icon: (iconStyle?: string) => <ScoreOutlinedIcon className={iconStyle} />,
            component: Reports,
            title: t('reports'),
            role: [],
            children,
          },
      );
      // To prevent pushing same routes if RoutesBuilder runs again
      setReportRoutsIsBuild(true);
    }

    if (!currentUser.userId) {
      return (
        <>
          <Route path="/" component={SignIn} />
          <Route render={() => <Redirect to="/" />} />
        </>
      );
    } else {
     return (
      <Switch>
        {
          Routes.map((route: RoutesInterface, index: number) => {
            if (!route.parentMenu) {
              if (isUserAllowed(currentUser.userRoles, route.role)) {
                return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
              } else {
                return (<Route key={index} path={route.path} exact={route.exact}><Redirect to="/dashboard" /></Route>);
              }
            } else {
              return route.children!.map((childRoute: RoutesInterface, childIndex: number) => {
                if (isUserAllowed(currentUser.userRoles, childRoute.role)) {
                  return (<Route key={childIndex} path={childRoute.path} exact={childRoute.exact} component={childRoute.component} />);
                } else {
                  return (<Route key={childIndex} path={childRoute.path} exact={childRoute.exact}><Redirect to="/dashboard" /></Route>);
                }
              });
            }
          })
        }
        <Route render={() => <Redirect to="/dashboard"/>}/>
      </Switch>
      );
    }
  };

  return (
    <>
      <Router>
        <ApplicationBar handleDrawerOpen={handleDrawerOpen} open={!!currentUser.userId ? open : false} />
        <MenuDrawer currentUser={currentUser} handleDrawerClose={handleDrawerClose} open={!!currentUser.userId ? open : false} />
        <div className={`${clsx(classes.content, {[classes.contentShift]: !!currentUser.userId ? open : false})} ${styles.App_content}`}>
          {loader ? <Loading size="large" /> : RoutesBuilder()}
        </div>
      </Router>
      {notificationMsg && (
        <Notification
          type="error"
          msg={notificationMsg}
          autoHideDuration={6000}
        />
      )}
    </>
  );
};

export default App;
