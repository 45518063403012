import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser } from 'userSlice';
import { emptyUser } from 'interfaces/user';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import styles from './ApplicationBar.module.scss';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { BackendApp } from 'libs/App';
import Loading from 'components/Layout/Loading/Loading';

// Initialize backend methods
const backendLib = BackendApp();

const drawerWidth = 240;

const useStylesDrawer = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
  }),
);

interface Props {
  open: boolean;
  handleDrawerOpen: () => void;
}

const ApplicationBar = (props: Props) => {
  const dispatch = useDispatch();
  const { userId } = useSelector(selectUser);
  const { open, handleDrawerOpen } = props;
  const [logoutProcessing, setLogoutProcessing] = useState(false);

  const classesDrawer = useStylesDrawer();

  const handleLogout = () => {
    setLogoutProcessing(true);
    backendLib.userSignOut()
      .then(() => {
        setLogoutProcessing(false);
        dispatch(setUser(emptyUser));
      });
  };

  return (
    <AppBar
      position="static"
      className={clsx(classesDrawer.appBar, {
        [classesDrawer.appBarShift]: open,
      })}
    >
      <Toolbar className={styles.header}>
        <div className={styles.header_icons}>
          {userId && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classesDrawer.menuButton, open && classesDrawer.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link to={'/'} className={styles.header_logo}>
            <div className={styles.header_logoTitle}>PayTip</div>
          </Link>
        </div>
        {userId && (
          <IconButton color="inherit" onClick={handleLogout}>
            {logoutProcessing ? <Loading size="small-white" /> : <ExitToAppIcon />}
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default ApplicationBar;
