// TODO: Refactor this file. Is to long.

import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './CustomerService.module.scss';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { checkEmail } from 'utils/validators';
import Loading from 'components/Layout/Loading/Loading';
import { BackendApp } from 'libs/App';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import ReadOnlyTable from 'components/DataTable/AppDataTables'

// Initialize backend methods
const backendLib = BackendApp();

const useStyles = makeStyles(() => ({
  card: {
    width: '98%',
    margin: '2rem auto',
  },
  searchText: {
    margin: '1rem',
  },
  searchIcon: {
    color: 'grey',
  },
  cardContent: {
    display: 'grid',
  },
  cardContent_row: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '32%',
    gridGap: '2%',
  },
  switch: {
    marginLeft: '0',
  },
  formControl: {
    minWidth: '195px',
  },
  saveButton: {
    minWidth: '105px',
  },
  accordion: {
    '&:last-child': {
      margin: '16px 0 !important',
    },
  },
}));

const CustomerService = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const view = props.location.pathname.replace('/', '');
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [smallLoader, setSmallLoader] = useState(true);
  const [walletData, setWalletData] = useState<null | { firebaseUser: any, userData: any, binaries: any }>(null);
  const [emailError, setEmailError] = useState(false);
  const [dataError, setDataError] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [statusInfo, setStatusInfo] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [operations, setOperations] = useState<any[]>([]);
  const [actions, setActions] = useState<object>();

  const [ticklers, setTicklers] = useState<string[][]>([]);
  const [ticklerErrorMsg, setTicklerErrorMsg] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchEmail(event.target.value);
  };

  const handleSearch = (event?: React.FormEvent<EventTarget>) => {
    event?.preventDefault();
    setEmailError(!!checkEmail(searchEmail));
    if (!checkEmail(searchEmail)) {
      if (!loading) {
        const operationRequest = {
          n: 'AccountGet',
          d: {
            i: searchEmail,
          },
        };

        setLoading(true);
        setDataError('');
        backendLib.callBackOffice(operationRequest)
          .then((result: any) => {
            setLoading(false);            
            if (result.data.d.userData) {              
              setWalletData(result.data.d);
              setStatusInfo(statusMapper(result.data.d.userData.v ? result.data.d.userData.v.s : t('error')));
              return result.data.d;
            } else {
              setDataError(t('no_response_user_data'));              
            }
          })
          .then((userData:any)=>{
            const operationRequest2 = {
              n: 'UserTicklersGet',
              d: {
                i: userData.firebaseUser.uid,                
                c:'customer-service'
              },
            };
            console.log(operationRequest2);
            return backendLib.callBackOffice(operationRequest2).catch((error:Error)=>{              
              throw new Error('Tickler retrieve error');
            })
          })
          .then((ticklerResult:any)=>{
            var ticklerArray:any[] = [];
            console.log(ticklerResult, ticklerResult.data.d.v)
            // map tickler to rows
            ticklerResult.data.d.v.forEach((dayItem:any)=>{
              Object.values(dayItem).forEach((item:any)=>{
                console.log('Item', item);
                var rrow = [
                  (new Date(item.ct)).toUTCString(),
                  item.a+'/'+ item.b,                  
                  item.r,                  
                  item.d.a ? JSON.stringify(item.d.a) : '',
                ]
                console.log(rrow);
                ticklerArray.push(rrow);
              })
            })
            setTicklers(ticklerArray.reverse())
          })
          .catch((error: any) => {            
            if(error.message.includes('Tickler retrieve error')){              
              setTicklerErrorMsg(t('cs.ticklers.accessDenied'));
            }if(error.message.includes('Cannot read property \'firebaseUser\' of undefined')){              
              setDataError(t('userData.errorRetrieving'));
            }
            else{
              setDataError(error.message);              
            }            
          })
          .finally(()=>{
            setLoading(false);
          });
      }
      setWalletData(null);
    }
  };

  const handleActionChange = (event: React.FormEvent<EventTarget>, category: string) => {
    event.persist();
    if ((event.target as HTMLSelectElement).value) {
      setActions((state: object) => ({ ...state, [category]: (event.target as HTMLSelectElement).value }));
    } else {
      setActions((state: any) => {
        delete state[category];
        return { ...state };
      });
    }
  };

  const handleActionCTA = () => {
    if (actions) {
      const actionsToDoArray = Object.values(actions);
      const promises: Array<Promise<any>> = [];
      actionsToDoArray.forEach((actionToDo) => {
        if (walletData?.firebaseUser.uid) {
          setLoading(true);
          promises.push(backendLib._callAPI('boAPI', { n: actionToDo, d: { i: walletData.firebaseUser.uid, c: view } })
            .then((result: any) => result)
            .catch((error: any) => error));
        } else {
          console.warn('No required data!');
        }
      });

      if (promises.length) {
        Promise.all(promises)
          .then((result: any) => {
            console.log(result);
            handleSearch();
          })
          .catch((error: any) => console.log(error));
      }
    }
  };

  const statusMapper = (statusId: string) => {
    switch (statusId) {
      case 'A':
        return `${statusId} - ${t('correct_verification')}`;
      case 'B':
        return `${statusId} - ${t('locked')}`;
      case 'D':
        return `${statusId} - ${t('have_data')}`;
      case 'P':
        return `${statusId} - ${t('waiting')}`;
      case 'U':
        return `${statusId} - ${t('undefined')}`;
      default:
        return `${statusId} - ${t('no_described_status')}`;
    }
  };

  useEffect(() => {
    setSmallLoader(true);
    backendLib._callAPI('boAPI', { n: 'OperationsGet', d: { i: view } })
      .then((result: any) => {
        const categoriesArray = [];
        const map = new Map();
        for (const item of result.data.d) {
          if (!map.has(item.v.c)) {
            map.set(item.v.c, true);
            categoriesArray.push(item.v.c);
          }
        }
        setCategories(categoriesArray);
        setOperations(result.data.d);
      })
      .catch((error: any) => console.log(error))
      .finally(() => setSmallLoader(false));
  }, [props.location.pathname, view]);

  return (
    <Card className={classes.card} elevation={3}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSearch}
      >
        <div className={styles.customerService_cardHeaderWrapper}>
          <CardHeader
            subheader={t('wallet_data')}
            title={t('wallet')}
          />
          <button
            className="no-display"
            type="submit"
          >
            Hidden submit button
          </button>
          <TextField
            className={classes.searchText}
            variant="outlined"
            helperText={emailError ? t('its_not_email') : ''}
            error={emailError}
            placeholder={t('pass_user_email')}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.searchIcon}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Divider />
        {
          dataError && (
            <CardContent>
              <div className={styles.customerService_dataError}>
                {dataError}
              </div>
            </CardContent>
          )
        }
        {
          !dataError && !emailError && loading && (
            <CardContent>
              <div className={styles.customerService_loader}>
                <Loading size="medium" />
              </div>
            </CardContent>
          )
        }
        {
          !emailError && walletData && !loading && (
            <>
              <CardContent>
                <div className={classes.cardContent}>
                  <div className={classes.cardContent_row}>
                    <TextField
                      fullWidth
                      value={walletData.firebaseUser.uid}
                      autoComplete="off"
                      name="uid"
                      label={t('wallet_id')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.dn || t('data_hidden')}
                      autoComplete="off"
                      name="surname"
                      label={t('name_and_surname')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={statusInfo}
                      autoComplete="off"
                      name="status"
                      label={t('account_status')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div className={classes.cardContent_row}>
                    <TextField
                      fullWidth
                      value={walletData.firebaseUser.email || t('data_hidden')}
                      autoComplete="off"
                      name="email"
                      label={t('email')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.bn || t('data_hidden')}
                      autoComplete="off"
                      name="bank_account_number"
                      label={t('bank_account_number')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.c || t('data_hidden')}
                      autoComplete="off"
                      name="city"
                      label={t('city')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div className={classes.cardContent_row}>
                    <TextField
                      fullWidth
                      value={walletData.userData.z || t('data_hidden')}
                      autoComplete="off"
                      name="zip_code"
                      label={t('zip_code')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.s || t('data_hidden')}
                      autoComplete="off"
                      name="street"
                      label={t('street')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.sno || t('data_hidden')}
                      autoComplete="off"
                      name="building_number"
                      label={t('building_number')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div className={classes.cardContent_row}>
                    <TextField
                      fullWidth
                      value={walletData.userData.cs || t('data_hidden')}
                      autoComplete="off"
                      name="nationality"
                      label={t('nationality')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.v ? walletData.userData.v.i1 || t('data_hidden') : t('data_hidden')}
                      autoComplete="off"
                      name="pesel"
                      label={t('pesel')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.v ? walletData.userData.v.idt1 === 'I' ? t('id_card') : walletData.userData.v.idt1 === 'P' ? t('passport') : t('data_hidden') : t('data_hidden')}
                      autoComplete="off"
                      name="regon"
                      label={t('document_type')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div className={classes.cardContent_row}>
                    <TextField
                      fullWidth
                      value={walletData.userData.v ? walletData.userData.v.id1 || t('data_hidden') : t('data_hidden')}
                      autoComplete="off"
                      name="document_number"
                      label={t('document_number')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.v ? walletData.userData.v.i2 || t('data_hidden') : t('data_hidden')}
                      autoComplete="off"
                      name="nip"
                      label={t('ids.id2')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.v ? walletData.userData.v.i3 || t('data_hidden') : t('data_hidden')}
                      autoComplete="off"
                      name="regon"
                      label={t('ids.id3')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div className={classes.cardContent_row}>
                    <TextField
                      fullWidth
                      value={walletData.userData.e2 || t('data_hidden')}
                      autoComplete="off"
                      name="extra_name"
                      label={t('extras.e2')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={walletData.userData.o && walletData.userData.o.includes('ofiara.org')? t('userData.originOfiara')  : t('userData.originPaytip')}
                      autoComplete="off"
                      name="extra_name"
                      label={t('userData.origin')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>

                </div>
              </CardContent>
              <Divider />
              <CardActions>
                {!!categories.length && (
                  <div className={styles.customerService_actionsWrapper}>
                    {categories.map((category, index) => (
                      <FormControl key={index} variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-action">{category}</InputLabel>
                        <Select
                          native
                          onChange={(event) => handleActionChange(event, category)}
                          label={category}
                          inputProps={{
                            name: 'select-action',
                            id: 'select-action',
                          }}
                        >
                          <option aria-label="None" value="" />
                          {operations.map((operation, i) => (
                            <React.Fragment key={i}>
                              {operation.v.c === category && (
                                <option value={operation.k}>{operation.v.t}</option>
                              )}
                            </React.Fragment>
                          ))}
                        </Select>
                      </FormControl>
                    ))}
                    <Button
                      className={classes.saveButton}
                      variant="contained"
                      color="primary"
                      type="button"
                      size="large"
                      onClick={handleActionCTA}
                      disabled={!actions || !Object.values(actions).length || !walletData.firebaseUser.uid}
                    >
                      {smallLoader ? (<Loading size="small" />) : t('save')}
                    </Button>
                  </div>
                )}
              </CardActions>
            </>
          )
        }
      </form>
      {walletData?.binaries.v && !loading && (
        <>
          <Divider />
          <CardContent>
            <ExpansionPanel className={classes.accordion}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={styles.customerService_imagesTitle}>{t('document_photos')}</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={styles.customerService_imageWrapper}>
                  <p>
                    {walletData.binaries.v.d3 ? t('id_front') : t('passport_page')}
                  </p>
                  <img
                    className={styles.customerService_imageWrapper_image}
                    src={walletData.binaries.v.d1}
                    alt="Document 1"
                  />
                  <p>{walletData.binaries.v.d3 ? t('id_back') : 'Selfie'}</p>
                  <img
                    className={styles.customerService_imageWrapper_image}
                    src={walletData.binaries.v.d2}
                    alt="Document 2"
                  />
                  {walletData.binaries.v.d3 && (
                    <>
                      <p>Selfie</p>
                      <img
                        className={styles.customerService_imageWrapper_image}
                        src={walletData.binaries.v.d3}
                        alt="Document 3"
                      />
                    </>
                  )}

                  <p>
                    {t('bank_document')}
                  </p>
                  <img
                    className={styles.customerService_imageWrapper_image}
                    src={walletData.binaries.v.d4}
                    alt="Document 4"
                  />
                  {walletData.binaries.v.d5 && (
                    <>
                      <p>{t('additional_doc_scan')}</p>
                      <img
                        className={styles.customerService_imageWrapper_image}
                        src={walletData.binaries.v.d5}
                        alt="Document 5"
                      />
                    </>
                  )}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardContent>
        </>
      )}
      {
        walletData && !loading && (
          <>
            <Divider />
            <CardContent>
              <ExpansionPanel className={classes.accordion}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={styles.customerService_imagesTitle}>{t('cs.ticklers.panelTitle')}</div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={styles.customerService_imageWrapper}>
                    <ReadOnlyTable 
                    title={t('cs.ticklers.title')}
                    columns={['cs.ticklers.when', 'cs.ticklers.catAcatB', 'cs.ticklers.resource', 'cs.ticklers.record']} 
                    errorMsg={ticklerErrorMsg} 
                    isLoading={loading} 
                    rows={ticklers}/>                                          
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </CardContent>
          </>
        )
      }

    </Card>
  );
};

export default withRouter(CustomerService);
