import React from 'react';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  
  return (
   <div className={styles.dashboard}>
      Tu będzie Dashboard :)
   </div>
  );
};

export default Dashboard;
