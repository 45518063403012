export const pl = {
  no_permissions: 'Brak uprawnień aby zalogować się do Back office',
  verify_email: 'Proszę zweryfikuj swój email',
  wrong_login_or_password: 'Nieprawidłowy login lub hasło',
  login: 'Logowanie',
  login_with_social: 'Zaloguj poprzez social media',
  login_with_facebook: 'Zaloguj przez Facebook',
  or_login_with_email: 'lub zaloguj używając adresu email',
  password_is_required: 'Hasło jest wymagane',
  dont_have_account: 'Nie masz konta?',
  sign_in: 'Zarejestruj się',
  error: 'Błąd',
  no_response_user_data: 'Brak zwróconych danych użytkownika!',
  correct_verification: 'Weryfikacja poprawna',
  locked: 'Zablokowane',
  have_data: 'Podano dane',
  waiting: 'Oczekuję',
  undefined: 'Niezdefiniowany',
  no_described_status: 'Brak opisanego statusu',
  wallet_data: 'Dane portfela',
  wallet: 'Portfel',
  its_not_email: 'To nie jest adres e-mail',
  pass_user_email: 'Podaj E-mail użytkownika',
  wallet_id: 'ID portfela',
  name_and_surname: 'Imię i nazwisko',
  account_status: 'Status konta',
  email: 'E-mail',
  active: 'Aktywne',
  field_required: 'Pole wymagane',
  type_correct_email: 'Wprowadź poprawny adres email',
  dashboard: 'Pulpit',
  customer_service: 'Obługa klienta',
  transactions: 'Transakcje',
  settings: 'Ustawienia',
  reports: 'Raporty',
  report_name: 'Nazwa raportu',
  action: 'Akcja',
  generate: 'Generuj',
  filter: 'Flitruj',
  sort: 'Sortuj',
  no_records_found: 'Nie znaleziono danych',
  next_page: 'Następna strona',
  previous_page: 'Poprzednia strona',
  rows_per_page: 'Wpisów na stronę',
  of: 'z',
  search: 'Szukaj',
  download_csv: 'Pobierz CSV',
  print: 'Drukuj',
  view_columns: 'Zobacz kolumny',
  filter_table: 'Filtruj tabelę',
  all: 'Wszystkie',
  filters: 'Filtry',
  reset: 'Reset',
  show_columns: 'Pokaż kolumny',
  show_hide_table_columns: 'Pokaż/Ukryj kolumny tabeli',
  row_selected: 'Wiersz(y) wybranych',
  delete: 'Usuń',
  delete_selected_rows: 'Usuń wybrane wiersze',
  report_parameters: 'Parametry raportu',
  download_report: 'Pobierz raport',
  generating_report: 'Generowanie raportu',
  report_ready_to_download: 'Raport gotowy do pobrania.',
  click_button_to_download: 'Kliknij w przycisk aby go pobrać.',
  generate_report: 'Generuj raport',
  from: 'od',
  to: 'do',
  date_from_to_error: 'Data "do" musi być większa od daty "od"',
  document_photos: 'Zdjęcia dokumentów',
  bank_account_number: 'Numer rachunku bankowego',
  city: 'Miasto',
  zip_code: 'Kod pocztowy',
  street: 'Ulica',
  building_number: 'Numer budynku/lokalu',
  id_front: 'Front dowodu osobistego',
  id_back: 'Tył dowodu osobistego',
  passport_page: 'Strona z danymi paszportu',
  select_status: 'Wybierz status',
  save: 'Zapisz',
  nationality: 'Obywatelstwo',
  user_status: 'Status użytkownika',
  pesel: 'PESEL',
  document_type: 'Typ dokumentu',
  document_number: 'Numer dokumentu',
  ids: {
    id2: 'NIP',
    id3: 'REGON'
  },
  extras: {
    e2: 'Dodatkowa nazwa'
  },
  userData: {
    origin: 'Brand',
    originPaytip: 'PAYTIP',
    originOfiara: 'OFIARA',
    errorRetrieving: 'Nie znaleziono pasujących wyników.'
  },
  additional_doc_scan: 'Dokument dodatkowy',
  select_action: 'Wybierz akcję',
  data_hidden: 'Brak danych lub dane ukryte/brak uprawnien',
  passport: 'Paszport',
  id_card: 'Dowód osobisty',
  bank_document: 'Dokument bankowy',
  cs: {
    c1: 'jakas kolumna',
    ticklers: {
      panelTitle: 'Ticklery',
      accessDenied: 'Brak uprawnień do podglądu ticklerów',
      when: 'Data',
      catAcatB: 'Rodzaj',
      resource: 'Encja',
      record: 'Dane operacji',
      title: 'Ticklery użytkownika'
    }
  },
  
  table: {
    e: 'error',
    loading: 'Ładowanie danych...'
  },
  operations: {
    menuLabel: 'Operacje',
    headerTitle: 'Automaty',
    headerSubTitle: 'i zadania batchowe',
    placeCreate: {
      title: 'Ofiara - dodawanie nowych miejsc',
      emailLabel: 'Email do podpięcia',
      passwordLabel: 'Hasło do logowania',
      submit: 'Utwórz miejsce',
      alreadyExists: 'Miejsce istnieje',
      success: 'Miejsce dodane, ustawiony email jako login oraz ustawione hasło',
      error: 'Niepoprawne wykonanie operacji',
      choosePlaceTitle: 'Wyszukaj miejsce do dodania',
      choosePlaceNoResults: 'Aby zobaczyć wyniki rozpocznij wpisywanie nazwy'
    }
  }
};
