import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'userSlice';
import styles from './MenuDrawer.module.scss';
import { isUserAllowed } from 'utils/isUserAllowed';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Routes } from 'config/Routes';
import RoutesInterface from 'interfaces/routes';

const useStyles = makeStyles(() => ({
  listItem: {
    'padding': '0',
    '& .MuiListItemIcon-root': {
      minWidth: '3.125rem',
    },
  },
  listItemText: {
    margin: '0',
  },
  link: {
    width: '100%',
    padding: '1.2rem 1.6rem',
    color: 'inherit',
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    textDecoration: 'none',
  },
  activeLink: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
}));

const drawerWidth = 240;

const useStylesDrawer = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    menu: {
      paddingTop: 0,
    },
  }),
);

interface Props {
  open: boolean;
  handleDrawerClose: () => void;
  currentUser?: any;
}

interface ContextProps {
  currentUser: {
    userRoles: string[],
  };
}

const MenuDrawer = (props: Props) => {
  const { userRoles } = useSelector(selectUser);
  const classes = useStyles();
  const classesDrawer = useStylesDrawer();
  const theme = useTheme();
  const { open, handleDrawerClose,  currentUser} = props;

  // You can menage menu from Routes.ts
  const menuBuilder = () =>
    Routes.sort((a: { menuOrder: number }, b: { menuOrder: number }) => (a.menuOrder - b.menuOrder)).map((route: RoutesInterface, index: number) => {
      if (route.menuOrder) {
        if (!route.parentMenu) {
          if (isUserAllowed(userRoles, route.role)) {
            return (
              <ListItem key={index} className={classes.listItem} button>
                <NavLink
                  to={route.path}
                  className={classes.link}
                  activeClassName={classes.activeLink}
                >
                  <ListItemIcon>{route.icon()}</ListItemIcon>
                  <ListItemText className={classes.listItemText} primary={route.title} />
                </NavLink>
              </ListItem>
            );
          }
        } else {
          const subMenu = route.children!
            .sort((a: { menuOrder: number }, b: { menuOrder: number }) => (a.menuOrder - b.menuOrder))
            .map((child: RoutesInterface, subIndex: number) => {
              if (isUserAllowed(userRoles, child.role)) {
                return (
                  <ListItem key={subIndex} className={classes.listItem} button>
                    <NavLink
                      to={child.path}
                      className={classes.link}
                      activeClassName={classes.activeLink}
                    >
                      <ListItemIcon className={styles.menuDrawer_submenuIndent}>{child.icon()}</ListItemIcon>
                      <ListItemText className={classes.listItemText} primary={child.title} />
                    </NavLink>
                  </ListItem>
                );
              }
              return null;
            });

          if (!subMenu.every((element) => element === null)) {
            return (
              <div key={index}>
                <ListItem className={classes.listItem} button>
                  <NavLink
                    to={route.children![0].path}
                    className={classes.link}
                  >
                    <ListItemIcon>{route.icon()}</ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={route.title} />
                  </NavLink>
                </ListItem>
                <div>
                  <Divider />
                  {subMenu}
                  <Divider />
                </div>
              </div>
            );
          }
        }
      }
      return null;
    });

  return (
    <Drawer
      className={classesDrawer.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classesDrawer.drawerPaper,
      }}
    >
      <div className={classesDrawer.drawerHeader}>
        <div className={styles.menuDrawer_userEmail}>{currentUser.email}<br/><span className={styles.menuDrawer_userId}>{currentUser.userId}</span></div>        
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List className={classesDrawer.menu}>
        {menuBuilder()}
      </List>
    </Drawer>
  );
};

export default MenuDrawer;
