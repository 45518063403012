import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => 
  createStyles({
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    root:{
      background: '#fff',
      '& .MuiFormLabel-root': {
        fontSize: '1.2rem',
        fontFamily: 'Montserrat',
      },
      '& .MuiFormLabel-root.MuiInputLabel-outlined.MuiInputLabel-shrink': {
        fontSize: '1.5rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        fontSize: '1.5rem',
      },
    },  
    inputRoot: {
      fontSize: '1.2rem',
      fontFamily: 'Montserrat',
    },
}));


interface PlaceType {
  description: string;
  place_id: string;
  types: string[];
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

type Props = {
  title?: string | null,
  onSelected?: any,
  center: number[],
  radius: number,
  citiesAllowed?: string[],
  noResultsText?:string,
  typesAllowed?:string[]
};

const AddressInput = (props: Props) => {
  const {title, onSelected, center, radius, citiesAllowed, noResultsText, typesAllowed} = props;
  const classes = useStyles();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);
  
  

  if (typeof window !== 'undefined' && !loaded.current) {
    var apiKey:string = process.env.REACT_APP_GMAPS_API_KEY || '---not provided, check configuration---';
    console.log('Using GMaps API KEY', apiKey);
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key='+apiKey+'&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string, types: string[], location: any, radius: number }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;
    

    if (!autocompleteService.current && (window as any).google && (window as any).google.maps) {      
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ['establishment'], location: new (window as any).google.maps.LatLng(center[0], center[1]), radius: radius }, (results?: PlaceType[]) => {
      console.log(results);
      console.log(citiesAllowed, radius, center, typesAllowed);

      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          results = results.filter((item:PlaceType)=>{
            // return (item.types.includes('restaurant') || item.types.includes('night_club') || item.types.includes('cafe')  || item.types.includes('bar') || item.types.includes('meal_delivery')|| item.types.includes('meal_takeaway') || item.types.includes('food') ) && new RegExp(substrings.join("|")).test(item.structured_formatting.secondary_text.toLowerCase()) .includes('warszawa')
            var citiesMatch:boolean = true;
            var typesMatch:boolean = true;

            if(typesAllowed){
              typesMatch = false;
              typesMatch = item.types.some((r:string)=>{
                return typesAllowed.includes(r)
              })
            }

            if(citiesAllowed){
              citiesMatch = false;
              citiesMatch = new RegExp(citiesAllowed.join("|")).test(item.structured_formatting.secondary_text.toLowerCase());
            }
            console.log(item, citiesMatch, typesMatch);
            // return (item.types.includes('restaurant') || item.types.includes('night_club') || item.types.includes('cafe')  || item.types.includes('bar') || item.types.includes('meal_delivery')|| item.types.includes('meal_takeaway') || item.types.includes('food') ) && new RegExp(citiesAllowed.join("|")).test(item.structured_formatting.secondary_text.toLowerCase()) 

            return citiesMatch && typesMatch;
          })
          // console.log('Filtered', results);
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, loaded]);

  return (
    <Autocomplete
      id="google-map-demo"      
      fullWidth={true}
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot
      }}      
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete      
      includeInputInList
      filterSelectedOptions     
      noOptionsText={noResultsText} 
      value={value}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        // console.log('Selected:', newValue);
        setValue(newValue);
        onSelected(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={title} variant="outlined" fullWidth />
      )}      
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default AddressInput;

