import React, { useEffect, useState, useCallback } from 'react';

import { BackendApp } from 'libs/App';

import { useTranslation } from 'react-i18next';

import DataTable from 'components/DataTable/DataTable';
import styles from './AppDataTables.module.scss';
import Loading from 'components/Layout/Loading/Loading';


// Initialize backend methods
const backendLib = BackendApp();

interface Data {
  c0: string | JSX.Element;
  c1?: string | JSX.Element;
  c2?: string | JSX.Element;
  c3?: string | JSX.Element;
  c4?: string | JSX.Element;
  c5?: string | JSX.Element;
  c6?: string | JSX.Element;
  c7?: string | JSX.Element;
  c8?: string | JSX.Element;
  c9?: string | JSX.Element;
  c10?: string | JSX.Element;
}


interface HeadCell {
  name: string;
  label: string;
  options?: object;
}

interface Props {
  title?:string;
  columns: string[];
  rows: string[][];
  isLoading?: boolean;
  errorMsg?:string;  
  handleDrawerClose?: () => void;
}

export const ReadOnlyTable = (props: Props) => {

  const { isLoading, columns, errorMsg, rows, title } = props;
  const [dataRows, setDataRows] = useState<Data[]>([]);  
  const [openDialog, setOpenDialog] = useState(false);
  const [columnsSpecification, setColumnsSpecification] = useState<HeadCell[]>([]);

  const { t } = useTranslation();

  const columnsRedefine = () => {    
    var specification: HeadCell[] = [];

    for(var i:number = 0; i<columns.length ; i++ ){      
      var cellProps:any;

     if ((isLoading || errorMsg) && i == 0){
        cellProps = () => (
          {
            colSpan: 99,
            style: {textAlign: 'center'},
          }
        )
      }
      if ((isLoading || errorMsg) && i != 0){
        cellProps = () => (
          {
            style: {
              display: 'none',
            },
          }
        )
      }

      var columnNameKey = columns[i];
      var spec = {
        name: 'c'+i,       
        label: t(columnNameKey),
        options: {
         filter: true,
         sort: true,
         setCellProps: cellProps,
        },
       }       
      specification.push(spec);
    }
    setColumnsSpecification(specification);
  };

  const dataReload = () => {    
    var datas:Data[] = [];
    if(isLoading || errorMsg){
      var data: Data = {
        c0: errorMsg || <Loading size="medium"/>
      };
      datas.push(data);
    }else{
      rows.forEach((row:string[])=>{
        var data: any = {};
  
        for(var i:number = 0; i<row.length && i<10; i++){
          var key = 'c'+i;
          data[key] = row[i];
        }
        datas.push(data);
      })
    }
    setDataRows(datas);
  };

  useEffect(() => {
    columnsRedefine();
  },[columns]);

  useEffect(() => {
    columnsRedefine();
    dataReload();
  },[rows, errorMsg, isLoading]);


  const toggleClick = useCallback(() => {
    setOpenDialog((state) => !state);
  }, []);

  return (    
    <div className={styles.reports}>
      <DataTable
        columns={columnsSpecification}
        // rows={error ? [{name: error, action: ''}] : rows}
        rows={dataRows}
        // tableTitle={reportsParentTitle}
        tableTitle={title ? title : ''}
      />      
    </div>
  );
};

export default ReadOnlyTable;


