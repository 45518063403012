// Validation patterns
import validator from 'validator';
import i18next from 'i18next';

const t = (translation: string) => i18next.t(translation);

const checkEmpty = (value: string, optionalMsg?: string): string => {
  if (validator.isEmpty(value)) {
    return optionalMsg || t('field_required');
  }
  return '';
};

const checkEmail = (email: string, optionalMsg?: string): string => {
  if (!validator.isEmail(email)) {
    return optionalMsg || t('type_correct_email');
  }
  return '';
};

export {
  checkEmpty,
  checkEmail,
};
