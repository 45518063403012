import React from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

interface PropsInterface {
  columns: any[];
  rows: any[];
  tableTitle: string;
}

const DataTable = (props: PropsInterface) => {
  const { t } = useTranslation();

  const options: MUIDataTableOptions = {
    filterType: 'multiselect',
    selectableRows: 'none',
    print: false,
    downloadOptions: {
      filename: 'tableDownload.csv',
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true,
    },
    textLabels: {
      body: {
        noMatch: t('no_records_found'),
        toolTip: t('sort'),
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous_page'),
        rowsPerPage: t('rows_per_page:'),
        displayRows: t('of'),
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('download_csv'),
        print: t('print'),
        viewColumns: t('view_columns'),
        filterTable: t('filter_table'),
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset'),
      },
      viewColumns: {
        title: t('show_columns'),
        titleAria: t('show_hide_table_columns'),
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_selected_rows'),
      },
    },
  };

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      // Known MUI and TS issue
      // @ts-ignore
      MUIDataTableHeadCell: {
        root: {
          fontWeight: 700,
        },
      },
      MuiTableSortLabel: {
        root: {
          alignSelf: 'center',
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={props.tableTitle}
        data={props.rows}
        columns={props.columns}
        options={options}
      />
    </MuiThemeProvider>
  );
};

export default DataTable;
