import React from 'react';
import SignIn from 'views/SignIn/SignIn';
import Dashboard from 'views/Dashboard/Dashboard';
import Operations from 'views/Operations/Operations';
import CustomerService from 'views/CustomerService/CustomerService';
import i18next from 'i18next';

import DashboardIcon from '@material-ui/icons/Dashboard';
// import BlockIcon from '@material-ui/icons/Block';
// import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FlashOnIcon from '@material-ui/icons/FlashOn';

const t = (translation: string) => i18next.t(translation);

// Routes object.
// Items with menuOrder 0 don't appear in menu

// Role:
// Empty array - render for not logged in users
// For more information see UserRoles.ts

export const Routes = [
  {
    parentMenu: false,
    path: '/',
    menuOrder: 0,
    exact: true,
    icon: () => <></>,
    component: SignIn,
    title: '',
    role: [],
  },
  {
    parentMenu: false,
    path: '/dashboard',
    menuOrder: 1,
    exact: true,
    icon: (iconStyle?: string) => <DashboardIcon className={iconStyle} />,
    component: Dashboard,
    title: t('dashboard'),
    role: ['A1'],
  },
  {
    parentMenu: false,
    path: '/customer-service',
    menuOrder: 2,
    exact: true,
    icon: (iconStyle?: string) => <SupervisorAccountIcon className={iconStyle} />,
    component: CustomerService,
    title: t('customer_service'),
    role: ['A2'],
  },
  {
    parentMenu: false,
    path: '/osiemgwiazd',
    menuOrder: 3,
    exact: true,
    icon: (iconStyle?: string) => <FlashOnIcon className={iconStyle} />,
    component: Operations,
    title: t('operations.menuLabel'),
    role: ['A2'],
  },
];
