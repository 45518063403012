import React from 'react';
import styles from './SpinnerSmall.module.scss'; 

const SpinnerSmall = () => {
	return (
		<div className={styles['lds-spinner-small']}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
	);
}

export default SpinnerSmall;
