import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import User, { emptyUser } from 'interfaces/user';

const initialState: User = emptyUser;

export const slice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => action.payload,
  },
});

export const { setUser } = slice.actions;

export const selectUser = (state: RootState) => state.currentUser;

export default slice.reducer;
