import React, {useState} from 'react';
import styles from './Operations.module.scss';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { BackendApp } from 'libs/App';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddressInput from 'components/Cta/AddressInput'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loading from 'components/Layout/Loading/Loading';
import NotificationReopenable from 'components/Layout/Notification/NotificationReopenable'

const backendLib = BackendApp();

const useStyles = makeStyles(() => ({
  card: {
    width: '98%',
    margin: '2rem auto',
  },
  searchText: {
    margin: '1rem',
  },
  searchIcon: {
    color: 'grey',
  },
  cardContent: {
    display: 'grid',
  },
  cardContent_row: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '49%',
    gridGap: '2%',
  },
  switch: {
    marginLeft: '0',
  },
  formControl: {
    minWidth: '195px',
  },
  saveButton: {
    minWidth: '105px',
  },
  accordion: {
    '&:last-child': {
      margin: '16px 0 !important',
    },
  },
  newPlace: {    
      display: 'block'
  }
}));


const Operations = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [placeOvertakeEmail, setPlaceOvertakeEmail] = useState<string>('');
  const [placeOvertakePassword, setPlaceOvertakePassword] = useState<string>('');
  const [placeSelected, setPlaceSelected] = useState<any>({});
  const [placeProcessing, setPlaceProcessing] = useState<boolean>(false);
  const [notificationType, setNotificationType] = useState<'update' | 'error' | 'info' | 'save'>('info');
  const [notificationMsg, setNotificationMsg] = useState<string>('');
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const onPlaceSelected = (place: any) => {    
    setPlaceSelected(place);
  };

  const onPlaceCreate = (event: any) => {
    setShowNotification(false);
    console.log(placeSelected, placeOvertakeEmail);
    setPlaceProcessing(true);
    return backendLib.beneficiaryForPlace(placeSelected.place_id, 'G', placeSelected.structured_formatting.main_text, placeSelected.structured_formatting.secondary_text, placeSelected.types)
    .then((result:any) => {      
      return result;
    })
    .then((result:any)=>{  
      if(result && result.u){
        var i:string = ''+result.u.user.email+';'+placeOvertakeEmail;
        if(placeOvertakePassword){
          i += ';'+placeOvertakePassword
        }
        i+= "|";        
        return backendLib._callAPI('boAPI', {n: 'AccountOvertake', d: {i: i, c:'customer-service'}});
      }          
    })
    .then((result:any)=>{
      if(result){        
        setNotificationType('save');
        setNotificationMsg(t('operations.placeCreate.success'))
        setShowNotification(true);
      }else{
        setNotificationMsg(t('operations.placeCreate.alreadyExists'))
        setNotificationType('update');
        setShowNotification(true);
      }      
    })
    .catch((error:any)=>{
      setNotificationMsg(t('operations.placeCreate.error'))
      setNotificationType('error');
      setShowNotification(true);
    })
    .finally(()=>{
      setPlaceOvertakeEmail('');
      setPlaceOvertakePassword('');
      setPlaceProcessing(false);
    })
  };

  const onSomeEmail = (event: any) => {    
    setPlaceOvertakeEmail(event.currentTarget.value);
  };
  const onSomePass = (event: any) => {    
    setPlaceOvertakePassword(event.currentTarget.value);
  };

  const notificationClose = () => {    
    setShowNotification(false);
  };

  


  
  return (
    <Card className={classes.card} elevation={3}>
      <div className={styles.customerService_cardHeaderWrapper}>
      <CardHeader
            subheader={t('operations.headerSubTitle')}
            title={t('operations.headerTitle')}
          />
      </div>
      <Divider />
      <CardContent>
        
        <ExpansionPanel className={classes.accordion}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={styles.customerService_imagesTitle}>{t('operations.placeCreate.title')}</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.newPlace}>
              
              <div className={classes.cardContent}>
                  <div className={classes.cardContent_row}>
                  <AddressInput center={[52.406376,16.925167]} radius={1500000} onSelected={onPlaceSelected}  noResultsText={t('operations.placeCreate.choosePlaceNoResults')} title={t('operations.placeCreate.choosePlaceTitle')}></AddressInput>
                  
                  
              </div>
              <div className={classes.cardContent_row}>
              <TextField
                      fullWidth
                      value={placeOvertakeEmail}
                      autoComplete="off"
                      name="uid"
                      label={t('operations.placeCreate.emailLabel')}
                      margin="normal"
                      variant="outlined"
                      type="text"
                      InputProps={{
                        readOnly: false,
                      }}
                      onChange={(event: React.FormEvent<HTMLElement | any>): void => { onSomeEmail(event); }}
                    />
              <TextField
                      fullWidth
                      value={placeOvertakePassword}
                      autoComplete="off"
                      name="uid"
                      label={t('operations.placeCreate.passwordLabel')}
                      margin="normal"
                      variant="outlined"
                      type="password"
                      InputProps={{
                        readOnly: false,
                      }}
                      onChange={(event: React.FormEvent<HTMLElement | any>): void => { onSomePass(event); }}
                    />
              </div>
              <div className={classes.cardContent_row}>
                <div></div>
              <Button
                      className={classes.saveButton}
                      variant="contained"
                      color="primary"
                      type="button"
                      size="large"
                      onClick={onPlaceCreate}
                      disabled={placeProcessing || !placeOvertakeEmail || !placeOvertakePassword || !placeSelected}
                      
                    >
                      {placeProcessing ? (<Loading size="small" />) : t('operations.placeCreate.submit')}                      
                    </Button>
              </div>
              </div>
              </ExpansionPanelDetails>
              </ExpansionPanel>
      </CardContent>
      <NotificationReopenable type={notificationType} autoHideDuration={5000} show={showNotification} msg={notificationMsg} onClose={notificationClose}/>
    </Card>
    
  //  <div className={styles.dashboard}>
  //     Tu będzie Dashboard :)
  //  </div>
  );
};

export default Operations;
