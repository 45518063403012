export default interface User {
  userId: string;
  email: string;
  userRoles: string[];
  userReports: string[];
}

export const emptyUser: User = {
  userId: '',
  email: '',
  userRoles: [],
  userReports: [],
};
