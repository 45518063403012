export const en = {
  no_permissions: '[EN] Brak uprawnień aby zalogować się do Back office',
  verify_email: '[EN] Proszę zweryfikuj swój email',
  wrong_login_or_password: '[EN] Nieprawidłowy login lub hasło',
  login: '[EN] Logowanie',
  login_with_social: '[EN] Zaloguj poprzez social media',
  login_with_facebook: '[EN] Zaloguj przez Facebook',
  or_login_with_email: '[EN] lub zaloguj używając adresu email',
  password_is_required: '[EN] Hasło jest wymagane',
  dont_have_account: '[EN] Nie masz konta?',
  sign_in: '[EN] Zarejestruj się',
  error: 'Error',
  no_response_user_data: '[EN] Brak zwróconych danych użytkownika!',
  correct_verification: '[EN] Weryfikacja poprawna',
  locked: '[EN] Zablokowane',
  have_data: '[EN] Podano dane',
  waiting: '[EN] Oczekuję',
  undefined: '[EN] Niezdefiniowany',
  no_described_status: '[EN] Brak opisanego statusu',
  wallet_data: '[EN] Dane portfela',
  wallet: '[EN] Portfel',
  its_not_email: '[EN] To nie jest adres e-mail',
  pass_user_email: '[EN] Podaj E-mail użytkownika',
  wallet_id: '[EN] ID portfela',
  name_and_surname: '[EN] Imię i nazwisko',
  account_status: '[EN] Status konta',
  email: '[EN] E-mail',
  active: '[EN] Aktywne',
  field_required: '[EN] Pole wymagane',
  type_correct_email: '[EN] Wprowadź poprawny adres email',
  dashboard: 'Dashboard',
  customer_service: 'Customer service',
  transactions: 'Transactions',
  settings: 'Settings',
  reports: 'Reports',
  report_name: 'Report name',
  action: 'Action',
  generate: 'Generate',
  filter: 'Filter',
  sort: 'Sort',
  no_records_found: 'Sorry, no matching records found',
  next_page: 'Next page',
  previous_page: 'Previous page',
  rows_per_page: 'Rows per page',
  of: 'of',
  search: 'Search',
  download_csv: 'Download CSV',
  print: 'Print',
  view_columns: 'View columns',
  filter_table: 'Filter table',
  all: 'All',
  filters: 'Filters',
  reset: 'Reset',
  show_columns: 'Show columns',
  show_hide_table_columns: 'Show/Hide table columns',
  row_selected: 'Row(s) selected',
  delete: 'Delete',
  delete_selected_rows: 'Delete selected rows',
  report_parameters: 'Report parameters',
  download_report: 'Download report',
  generating_report: 'Generating report',
  report_ready_to_download: '[EN] Raport gotowy do pobrania.',
  click_button_to_download: '[EN] Kliknij w przycisk aby go pobrać.',
  generate_report: 'Generate report',
  from: 'from',
  to: 'to',
  date_from_to_error: 'Date "to" must be grater than "from"',
  document_photos: 'Document photos',
  bank_account_number: 'Bank account number',
  city: 'city',
  zip_code: 'Zip code',
  street: 'Street',
  building_number: 'Building number',
  id_front: '[EN] Front dowodu osobistego',
  id_back: '[EN] Tył dowodu osobistego',
  passport_page: '[EN] Strona z danymi paszportu',
  select_status: 'Select status',
  save: 'Save',
  nationality: 'Nationality',
  ser_status: 'User status',
  pesel: 'PESEL',
  document_type: 'Document type',
  document_number: 'Document number',
  select_action: 'Select action',
  data_hidden: '[EN] Brak danych lub dane ukryte/brak uprawnien',
  passport: 'Passport',
  id_card: 'ID Card',
  bank_document: 'Bank document',
};
